<template lang="pug">
.holding
  .box.holding-box
    b-checkbox(:value="holding.enabled" @input="toggleHolding")
      .holding-title {{ holding.name }}
    .holding-data(v-if="holding.enabled")
      .columns
        .column.is-narrow
          b-field.currency-picker(label="Currency")
            currency-select(:value="chosenCurrency" @input="updateCurrency" placeholder="Set Currency" prefix="")
        .column.is-narrow
          b-field(label="Amount")
            currency-input.input.currency-input(v-model="holding.amount" :currency="currencyOption" :precision="currencyPrecision")
        .column.is-narrow(v-if="hasSubunits")
          b-field.subunit-picker(label="Unit")
            subunit-select(:value="holding.subunit" @input="updateSubunit" :currency="holding.currency")
</template>

<script>
import { mapGetters } from 'vuex'

import subunits from '@/mixins/subunits'
import SubunitSelect from '@/components/SubunitSelect'
import CurrencySelect from '@/components/CurrencySelect'

export default {
  components: {
    CurrencySelect,
    SubunitSelect
  },
  mixins: [subunits],
  props: {
    holding: Object,
    lastCurrency: String,
    lastSubunit: String
  },
  computed: {
    ...mapGetters('currencies', ['getCurrencyById']),
    subunit() {
      return this.holding.subunit
    },
    chosenCurrency() {
      return this.getCurrencyById(this.holding.currency)
    }
  },
  methods: {
    updateCurrency(currency) {
      const { holding } = this
      holding.currency = currency.id
      let { subunit } = holding
      if ('subunits' in currency) {
        const defaultSubunit = Object.values(currency.subunits).find(
          ({ isDefault }) => isDefault
        )
        if (holding.subunit && holding.subunit in currency.subunits) {
          ;({ subunit } = holding)
        } else if (defaultSubunit) {
          subunit = defaultSubunit.id
        } else {
          subunit = null
        }
      } else {
        subunit = null
      }
      holding.subunit = subunit
      this.$emit('unit-change', { currency: currency.id, subunit })
    },
    updateSubunit(subunit) {
      const { holding } = this
      holding.subunit = subunit
      this.$emit('unit-change', { currency: holding.currency, subunit })
    },
    toggleHolding() {
      const { holding } = this
      if (holding.enabled) {
        holding.enabled = false
      } else {
        if (!holding.currency) {
          holding.subunit = this.lastSubunit
          this.updateCurrency(this.getCurrencyById(this.lastCurrency))
        }
        holding.enabled = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.holding
  margin-bottom: 1rem
  .b-checkbox
    width: 100%
.holding-box
  padding: 1rem
.holding-title
  font-weight: bold
  font-size: 1.1rem
.holding-data
  margin-left: 2rem
  margin-top: .75rem
</style>
