<template lang="pug">
section.section.container.net-worth-calculator
  b-loading(:is-full-page="true" :active="initializing" :can-cancel="false")
  .columns(v-if="!initializing")
    .column.is-12.header-flex
      h1.title.has-padding#entry-title Calculate my Net Worth
  .columns(v-if="!initializing")
    .column.is-12
      .has-padding
        b-message(title="About this tool" type="is-info" aria-close-label="Close")
          p
            | This is a simplified net worth calculator to quickly evaluate your
            | financial position within some broad categories that apply to most people.
            | If you want to add more categories and types or
            | have existing data you want to import,&nbsp
            router-link(:to="{name: 'sign_up'}") create an account instead.
            | &nbsp; You'll be able to do everything you can here and much more.
      .box#main-box
        b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
        b-steps(v-model="activeStep" :animated="true" :has-navigation="false")
          b-step-item(label="My Assets" :clickable="true")
            calculator-holding(v-for="asset in assets" :key="asset.typeId" :holding="asset" @unit-change="setLast" :last-currency="lastCurrency" :last-subunit="lastSubunit")
            .has-text-centered
              b-button.is-primary(size='is-large' @click="activeStep += 1") Next Step
          b-step-item(label="My Liabilities" :clickable="true")
            calculator-holding(v-for="liability in liabilities" :key="liability.typeId" :holding="liability" @unit-change="setLast" :last-currency="lastCurrency" :last-subunit="lastSubunit")
            .has-text-centered
              b-button.is-primary(size='is-large' @click="activeStep += 1") Next Step

          b-step-item(label="Net Worth" :clickable="true")
            div(v-if="networth.isConverting")
              h2.subtitle.nw-title Crunching numbers...
              b-progress
            div(v-if="!networth.isConverting")
              h2.nw-title
                | Your net worth is:&nbsp;
                currency-output.highlight(:value="networth.baseAmount" :currency="chosenCurrency" :date="today")
              h3.avo-subtitle
                | or, approximately&nbsp;
                currency-output(:value="networth.baseAmount" currency="🥑AAI" :date="today")
                | &nbsp;Australian Avocadoes.
              h4.change-currency See it in a different currency:&nbsp;
              .field.has-addons.output-currency-picker
                .control.currency-control
                  currency-select(:value="outputCurrency" @input="updateOutputCurrency" placeholder="Change Currency" prefix="")
                .control.subunit-control(v-if="outputHasSubunits")
                  subunit-select(:currency="networth.currency" v-model="networth.subunit")
            hr
            h2.subtitle Save your data
            .signup-form(v-if="activeStep === 2")
              p
                | Your data is stored in your browser right now, but may disappear.
                | Create a free account to save it permanently! Plus, you'll start to
                | be able to take advantage of our analytic tools.
              .sso-options
                .button.is-white.google-auth.auth-button(@click="signUpWithGoogle()")
                  span.icon-container
                    img(alt="Google" src="@/assets/img/sso/google.svg")
                  span.text Sign up with Google
              br
              b-field(label="Email")
                b-input(v-model="form.email")
              password-input(v-model="form.password" autocomplete="new-password" :required="true")
              b-field(label="Legal")
                b-switch(v-model="form.termsAccepted" type="is-success" :required="true")
                  strong I accept&nbsp;
                  | the&nbsp;
                  router-link(to="/about/terms" target="_blank") Terms of Service
                  | &nbsp;and&nbsp;
                  router-link(to="/about/privacy" target="_blank") Privacy Policy
              b-button.is-large.is-primary(@click="doRegistration" :disabled="formIsInvalid" :loading="isSigningUp") Save
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { sum } from 'lodash'
import dayjs from '@/dayjs'

import currencyFormatter from '@/misc/currencyFormatter'
import CurrencySelect from '@/components/CurrencySelect'
import SubunitSelect from '@/components/SubunitSelect'
import CurrencyOutput from '@/components/CurrencyOutput'
import CalculatorHolding from '@/components/CalculatorHolding'
import PasswordInput from '@/components/PasswordInput'
import { actionTaken } from '@/misc/analytics'
import { currencyWithSubunit } from '@/misc/helpers'

export default {
  name: 'CalculatorView',
  components: {
    CurrencySelect,
    SubunitSelect,
    CurrencyOutput,
    PasswordInput,
    CalculatorHolding
  },
  filters: {
    currencyFormatter
  },
  data() {
    const isAustralian = navigator.language === 'en-AU'
    return {
      loading: false,
      errorMessage: null,
      activeStep: 0,
      lastCurrency: isAustralian ? 'AUD' : 'USD',
      lastSubunit: null,
      assets: null,
      liabilities: null,
      today: dayjs()
        .startOf('day')
        .toDate(),
      // Registration
      isSigningUp: false,
      form: {
        email: '',
        password: '',
        termsAccepted: false,
        plan: 'starter',
        currency: isAustralian ? 'aud' : 'usd'
      },
      // Output state
      networth: {
        currency: null,
        subunit: null,
        baseAmount: null,
        isConverting: false
      }
    }
  },
  computed: {
    ...mapState('currencies', ['loadingCurrencies', 'currencies']),
    ...mapState('authentication', ['user']),
    ...mapGetters('currencies', ['getCurrencyById']),
    initializing() {
      return this.loadingCurrencies || this.loading
    },
    formIsInvalid() {
      if (!this.form.email.match(/@/)) return true
      if (this.form.password.length < 8) return true
      if (!this.form.termsAccepted) return true
      if (!this.form.plan) return true
      return false
    },
    outputCurrency() {
      const { currency } = this.networth
      if (!currency) {
        return null
      }
      return this.getCurrencyById(currency)
    },
    chosenCurrency() {
      const { currency, subunit } = this.networth
      return currencyWithSubunit(currency, subunit)
    },
    outputHasSubunits() {
      const { outputCurrency } = this
      if (!outputCurrency) {
        return false
      }
      return (
        'subunits' in outputCurrency &&
        Object.values(outputCurrency.subunits).length
      )
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(user) {
        if (user) {
          // Prevent double save
          if (this.loading) {
            return
          }
          if (this.isSigningUp) {
            this.loading = true
            this.importIntoAccount()
              .then(() => this.$router.push('/welcome'))
              .catch(() => {
                this.loading = false
              })
          } else {
            this.$router.push({ name: 'new_entry' })           
          }
        }
      }
    },
    assets: {
      handler(assets) {
        this.syncAssets(assets)
      },
      deep: true
    },
    liabilities: {
      handler(liabilities) {
        this.syncLiabilities(liabilities)
      },
      deep: true
    },
    activeStep: {
      handler(step) {
        // Net worth stage
        if (step === 2) {
          this.recalculate()
        }
        this.$scrollTo(document.getElementById('main-box'))
      }
    },
    // To prevent a flash of content, we set loading = true in mounted()
    // when loading currencies as it takes a little while for loadingCurrencies
    // to propagate. So we detect that propagation here and reset the flag
    loadingCurrencies(isLoading, wasLoading) {
      if (!wasLoading && isLoading) {
        this.loading = false
      }
    }
  },
  mounted() {
    if (this.currencies.length === 0) {
      this.loading = true
      this.loadCurrencies()
    }
    this.loadSavedState().then(() => this.recalculate())
  },
  methods: {
    ...mapActions('currencies', ['loadCurrencies', 'getExchangeRateOn']),
    ...mapActions('calculator', [
      'getAssets',
      'getLiabilities',
      'syncAssets',
      'syncLiabilities',
      'importIntoAccount'
    ]),
    ...mapActions('authentication', ['register', 'signInWithGoogle']),
    setLast({ currency, subunit }) {
      this.lastCurrency = currency
      this.lastSubunit = subunit
    },
    updateOutputCurrency(currency) {
      this.networth.currency = currency.id
      this.recalculate()
    },
    loadSavedState() {
      const initHolding = holding => {
        if (!holding.amount) {
          holding.amount = 0
        }
        if (!holding.currency) {
          holding.currency = holding.enabled ? this.lastCurrency : null
          holding.subunit = holding.enabled ? this.lastSubunit : null
        }
      }
      const assetsLoading = this.getAssets().then(assets => {
        assets.forEach(initHolding)
        this.assets = assets
      })
      const liabilitiesLoading = this.getLiabilities().then(liabilities => {
        liabilities.forEach(initHolding)
        this.liabilities = liabilities
      })
      return Promise.all([assetsLoading, liabilitiesLoading])
    },
    recalculate() {
      if (this.networth.currency === null) {
        this.networth.currency = this.lastCurrency
        this.networth.subunit = this.lastSubunit
      }
      this.networth.isConverting = true
      const promises = []
      this.assets.forEach(({ enabled, amount, currency, subunit }) => {
        if (enabled && amount) {
          promises.push(
            this.getExchangeRateOn({
              currency: currencyWithSubunit(currency, subunit),
              date: this.today
            }).then(rate => amount / rate)
          )
        }
      })
      this.liabilities.forEach(({ enabled, amount, currency, subunit }) => {
        if (enabled && amount) {
          promises.push(
            this.getExchangeRateOn({
              currency: currencyWithSubunit(currency, subunit),
              date: this.today
            }).then(rate => -amount / rate)
          )
        }
      })
      Promise.all(promises)
        .then(sum)
        .then(amount => {
          this.networth.baseAmount = amount
          this.networth.isConverting = false
        })
    },
    async signUpWithGoogle() {
      if (this.isSigningUp) {
        return
      }
      this.errorMessage = null
      this.form.termsAccepted = true
      this.isSigningUp = true
      this.signInWithGoogle({
        plan: this.form.plan,
        currency: this.form.currency
      })
        .then(() => {
          // Log conversion event to analytics
          actionTaken('sign_up', 1)        
        })
        .catch(err => {
          this.errorMessage = err.message
          this.isSigningUp = false
          this.$scrollTo(document.getElementById('main-box'))
        })
    },
    doRegistration() {
      if (this.isSigningUp) {
        return
      }
      this.errorMessage = null
      this.isSigningUp = true
      this.register(this.form)
        .then(() => {
          // Log conversion event to analytics
          actionTaken('sign_up', 1)         
        })
        .catch(err => {
          this.errorMessage = err.message
          this.isSigningUp = false
          this.$scrollTo(document.getElementById('main-box'))
        })
    }
  },
  metaInfo: {
    title: 'Calculate my Net Worth',
    meta: [
      {
        name: 'description',
        content:
          'Try our simple tool that lets you calculate your net worth. No sign up or personal information needed.'
      }
    ]
  }
}
</script>
<style lang="sass" scoped>
.header-flex
  display: flex
  align-items: center
  h1
    flex-grow: 1
    margin: 0
.nw-title
  font-size: 2rem
  text-align: center
  margin: 1.5rem 0
h3
  font-size: 1.5rem
  text-align: center
.highlight
  border-bottom: 3px double #777
  padding-bottom: .25rem
.change-currency
  font-size: 1.5rem
  text-align: center
  margin-top: 1.5rem
  margin-bottom: .5rem
.output-currency-picker
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  ::v-deep
    .multiselect__tags
      border-top-right-radius: 0
      border-bottom-right-radius: 0
    .control:last-child .multiselect__tags
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px
.sso-options
  display: flex
  flex-direction: column
  align-items: center
  margin: 0.5rem 2.5rem 0
.has-padding
  padding: 0.25rem 0.5rem
@media screen and (max-width: 500px)
  .header-flex
    margin-bottom: 1.5rem
  .column
    padding: 0
  .box
    padding: 1rem
</style>
